import http from "../http-common";


const getAll = () => {
  return http.get("");
};

  const getAllCareer = (PageNumber, PageSize) => {
    return http.get(`/api/Career?PageNumber=${PageNumber}&PageSize=${PageSize}`,  {
     
    });
  }

  const getCareerId = (id) => {
    return http.get(`/api/Career/${id}`,  {
     
    });
  }

  const createContact = (data) => {
    return http.post(`/api/Contact`, data , {
      headers: {
        "Access-Control-Allow-Origin": "*",
        // Authorization: `Bearer ${token}`,
        "Content-type": "application/json"
      }
    });
  };

  const config={responseType: 'blob'};
  const downloadFile = (id,fileName) => {
    return http.get(`/api/File/${id}/${fileName}`, config, {
     
    });
  }

const ApiService = {
  getAllCareer,
  getCareerId,
  createContact,
  downloadFile
  
};


export default ApiService;