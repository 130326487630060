import React, { useState, useEffect } from "react";
import "../Styles/Careers.css";
import Button from "@mui/material/Button";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router";
import { LinearProgress } from "@mui/material";
import Footer from "../components/Footer/Footer";
import ApiService from "../services/ApiService";
import { ReactComponent as Joinus } from "../images/joinOurTeam.svg";

const Careers = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [careerDetail, setCareerDetail] = useState([]);
  const [careerLength, setCareerLength] = useState(0);
  const PageSize = 5;
  const [count, setcount] = useState(0);

  const [PageNumber, setPageNumber] = useState(1);

  const loadNext = async () => {
    try {
      let careerCount = 0;
      let res = await ApiService.getAllCareer(PageNumber, PageSize);

      if (res?.data?.data !== null) {
        setCareerLength(res?.data?.data?.totalNoOfRecords);
        setCareerDetail((prevState) => [
          ...prevState,
          ...res?.data?.data?.getCareerDto,
        ]);
        setLoading(false);
        setPageNumber(PageNumber + 1);
        careerCount = res?.data?.data?.getCareerDto?.length;
        setcount(parseInt(count) + parseInt(careerCount));
      } else {
        setCareerDetail([]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err)
      setLoading(false);
    }
  };

  useEffect(() => {
    loadNext();
  }, []);

  const handleMore = () => {
    loadNext();
  };

  return (
    <>
      <div className="carrer-img-banner">
        <div className="carrer-banner-txt container">
          <div className="carrer-banner-txt-left">
            <h2>Careers</h2>
            <div>
              <h1>Join Our Team</h1>
              <p>
                We’re always looking for qualified candidates to join the
                Bridgetree family. If you’d like to be a part of our team, look
                through the job listings below and submit your resume to the
                listed email address.
              </p>
            </div>
          </div>

        
          <div style={{ displya: "flex", justifyContent: "center" }}>
            <Joinus className="carrer-banner-txt-right" />
          </div>
        </div>
      </div>

      <div className="job-opens">
        {loading ? (
          <LinearProgress />
        ) : (
          <>
            {careerDetail?.length === 0 ? (
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  "justify-content": "center",
                  width: "100%",
                  height: "20vh",
                }}
              >
               No Job Openings
              </h3>
            ) : (
              <>
                <Table responsive>
                  <thead>
                    <tr className="job-position">
                      <th>Position</th>
                      <th>Locations</th>

                      <th>Experience required</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {careerDetail.map((jobopening, index) => (
                      <>
                        <tr key={index} className="job-position">
                          <td className="position">{jobopening.title}</td>
                          <td className="place">{jobopening.location}</td>

                          <td>
                            {jobopening.experience}
                            <span className="display">
                              {" "}
                              experience required
                            </span>
                          </td>
                          <td className="more-detail-col">
                            <Button
                              className="more-detail"
                              onClick={() =>
                                navigate(`/careers/${jobopening.jobId}`)
                              }
                            >
                              More Details
                            </Button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>

                <div
                  style={{
                    width: "-webkit-fill-available",
                    textAlign: "center",
                  }}
                >
                  {careerLength === count ? (
                    <>
                      {" "}
                      <Button
                        className="load-more-inactive"
                        onClick={handleMore}
                      >
                        Load More
                      </Button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Button className="load-more" onClick={handleMore}>
                        Load More
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      <Footer />
    </>
  );
};

export default Careers;
